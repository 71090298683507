<template>
  <v-main>
    <AppBar title="Email Settings" :buttons="buttons" @onSave="onSave"></AppBar>
    <v-container>
      <Loading v-model="isLoading"></Loading>
      <Feedback ref="feedback"></Feedback>

    <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="12" sm="12">
            <v-card>
              <v-text-field
                v-model="orderEmailSetting.sender_name"
                ref="sender_name"
                label="Sender"
                filled
                background-color="#fff"
                hide-details="auto"
                :rules="rules.required"
                @change="onChange()"
              ></v-text-field>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12">
            <v-card>
              <v-text-field
                :value="orderEmailSetting.sender_email"
                ref="subject"
                label="Sender Email"
                filled
                background-color="#fff"
                hide-details="auto"
                readonly
              ></v-text-field>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12">
            <v-checkbox label="enable order pickup email" v-model="orderEmailSetting.enable_order_pickup_email"></v-checkbox>
          </v-col>

          <v-col cols="12" sm="12">
            <v-card>
              <v-select
                :items="templateNames"
                v-model="templateName"
                item-text="label"
                item-value="value"
                filled
                background-color="#fff"
                hide-details="auto"
                label="Select a Template"
              >
              </v-select>
            </v-card>
          </v-col>

          
          <v-col cols="12" v-for="template in orderEmailTemplates" :key="template.id" >
            <v-row v-if="template.name == templateName">
              <v-col cols="12" sm="12">
                <v-card>
                  <v-text-field
                    v-model="template.subject"
                    ref="subject"
                    label="Subject"
                    filled
                    background-color="#fff"
                    hide-details="auto"
                    :rules="rules.required"
                    @change="onChange()"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12">
                <v-card class="elevation-2">
<!-- <editor 
  v-model="template.body" 
  @change="onChange()"
  :rules="rules.required"
/> -->
<!-- <tiptap-vuetify
  v-model="template.body"
  :extensions="extensions"
  @change="onChange()"
  :rules="rules.required"
/>                   -->
                  <vue-editor id="editor" @change="onChange()"  v-model="template.body" :rules="rules.required"></vue-editor>
                  <!--<wysiwyg v-model="template.body" />-->
                </v-card>
              </v-col>

            </v-row>
          </v-col>
            
          


        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>
            Shortcodes
          </v-card-title>
          <v-card-text>
            <ul v-for="template in orderEmailTemplates" :key="template.id" v-show="template.name == templateName" class="text-left">
              <li v-for="shortcode in template.shortcodes" :key="shortcode"><strong>{{shortcode}}</strong></li>
            </ul>
          </v-card-text>
          
        </v-card>
      </v-col>
    </v-row>
    </v-form>

    </v-container>
  </v-main>
</template>
<script>
import AppBar from '../components/AppBar'
import { VueEditor} from "vue2-editor";
import gql from 'graphql-tag'
import {UPDATE_ORDER_EMAIL_TEMPLATES_MUTATION} from '../graphql/mutations'
import Feedback from '../components/Feedback'
import Loading from '../components/Loading'
// import Editor from '../components/Editor.vue'
// import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

//import wysiwyg from "vue-wysiwyg";

//var Block = Quill.import('blots/block');
//Block.tagName = 'DIV';
//Quill.register(Block, true);
//Quill.register('blots/block', Block)

export default {

  components: {
    AppBar,
    // VueEditor,
    Loading,
    Feedback,
    // Editor,
    VueEditor
    //wysiwyg
  },

  data:() => ({
    // extensions: [
    //   History,
    //   Blockquote,
    //   Link,
    //   Underline,
    //   Strike,
    //   Italic,
    //   ListItem,
    //   BulletList,
    //   OrderedList,
    //   [Heading, {
    //     options: {
    //       levels: [1, 2, 3]
    //     }
    //   }],
    //   Bold,
    //   Code,
    //   HorizontalRule,
    //   Paragraph,
    //   HardBreak
    // ],    
    valid: false,
    isLoading: false,
    rules: {
      required: [
        v => !!v || 'Required',
      ]
    },
    orderEmailSetting: {
      sender_name: null,
      sender_email: null,
      enable_order_pickup_email: 1
    },
    orderEmailTemplates: [],
    templateNames: [
      {label:'Ready for Pickup', value:'READY_FOR_PICKUP'},
      {label:'Order Pickup Reminder', value:'ORDER_PICKUP_REMINDER'},
    ],
    templateName: 'READY_FOR_PICKUP',
    customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["code-block"]],
  }),

  computed:{
    buttons() {
      return [
        { label:'Save', color:'error', click:'onSave', disabled:!this.valid }
      ]
    }
  },

  // watch: {
  //   orderEmailSetting: {
  //     handler() {
  //       this.locknav()
  //     },
  //     deep: true
  //   },
  //   orderEmailTemplates: {
  //     handler() {
  //       this.locknav()
  //     },
  //     deep: true
  //   }
  // },

  apollo: {
    orderEmailSetting() {
      return {
        query: gql`
            query orderEmailSetting {
              orderEmailSetting {
                id
                sender_name
                sender_email
                enable_order_pickup_email
              }
            }
        `
      }
    },
    orderEmailTemplates() {
      return {
        query: gql`
          query orderEmailTemplates {
            orderEmailTemplates {
              id
              name
              label
              subject
              body
              shortcodes
            }
          }
        `
      }
    },
  },

  methods: {
    onChange() {
      this.locknav()
    },
    onSave() {
      this.isLoading = true
      const settings = {
        id: this.orderEmailSetting.id,
        sender_name: this.orderEmailSetting.sender_name,
        sender_email: this.orderEmailSetting.sender_email,
        enable_order_pickup_email: this.orderEmailSetting.enable_order_pickup_email
      }
      const templates = this.orderEmailTemplates.map((t) => {
        return {id: t.id, subject: t.subject, body: t.body}
      })

      this.$apollo.mutate({mutation: UPDATE_ORDER_EMAIL_TEMPLATES_MUTATION, variables: {settings, templates}})
        .then(() => {
          this.unlocknav()
          this.isLoading = false
          this.$refs.feedback.success('Changes saved.')
        })
        .catch(() => {
          this.isLoading = false
          this.$refs.feedback.error('An error occured.')
        })
    }
  }
  
}
</script>